#avatarEditor {
    .editImage {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20%;
      background-color: #fafafa;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
      z-index: 9999;
      cursor: pointer;
      opacity: 0.4;
  
      span {
        display: block;
        margin-right: 10px;
      }
    }
  }
  
  .modal-avatar {
    .close {
      background: transparent;
      border: 1px #04227c solid;
      color: #04227c;
      border-radius: 0;
  
      &:hover {
        background: #04227c;
        color: #fff;
      }
    }
  
    .save {
      background: #88e1f2;
      border: 0;
      color: #0a3cab;
      border-radius: 0;
  
      &:hover {
        background: #0a3cab;
        color: #fff;
      }
    }
  }
  