@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

#root[aria-hidden=true] {
  display: none;
}

body {
  background: var(--color-primary);
  color: #fff;
  overflow-x:hidden;

  &.default {
    background: var(--color-primary);

    .bg-game {
      background: var(--color-primary);
    }

    .btn-team{
      background: var(--color-primary);
    }

    .team-name {
      // background: var(--color-primary);
      // color: #ffffff;
    }

    .navigation-app {
      button.no-color,
      .item-menu.no-color {
        background: #fcfc30;
      }
    }

    .user-info {
      background: var(--color-primary);
    }

    .action {
      background:var(--color-primary);
      @apply text-white;
    }

    .box-idea,
    .box-challenge,
    .box-ranking {
      border-color: #11beff;
    }
  }
  &:not(.has-contrast){
    &.agente-ti {
      @apply bg-blue;

      .btn-team {
      background:#07749D;
      }

      .team-name {
        @apply bg-blue;
      }
      .bg-game {
        @apply bg-blue;
      }
      .navigation-app {
        button.no-color,
        .item-menu.no-color {
          background: #11beff;

          &.is-active{
            @apply bg-purple;
            color:#11beff;
          }
        }
      }

      .user-info {
        @apply bg-blue;
      }

      .action {
        @apply bg-blue;
        @apply text-white;
      }

      .box-idea,
      .box-challenge,
      .box-ranking {
        border-color: #11beff;
      }
    }

    &.agente-negocial {
      @apply bg-purple;

      .team-name {
        @apply bg-purple;
        color: #fff;
      }

      .btn-team {
        background: var(--color-primary);
      }

      .bg-game {
        @apply bg-purple;
      }

      .navigation-app {
        button.no-color,
        .item-menu.no-color {
          @apply bg-purple-100
        }
      }

      .user-info {
        @apply bg-purple;
      }

      .action{
        @apply bg-purple;
        @apply text-white;
      }

      .box-idea,
      .box-challenge,
      .box-ranking {
        @apply border-purple-100;
        // border-color: #735cc6;
      }
    }
  }

  &.has-contrast{
    background: #000;

      .btn-team {
        background: #000;
        color:#fcfc30;
      }

      .team-name {
        color: #fcfc30;
      }

      .bg-game {
        background: #fcfc30;
      }

      .navigation-app {

        button.no-color,
        .item-menu.no-color,
        .item-menu.custom-color {
          background:#000;
          border:1px solid #fcfc30;
          color: #fcfc30;
        }
      }

      .user-info {
        background: #000;
        border-color:#fcfc30;
        color:#fcfc30;
      }

      .action {

        @apply bg-blue;
        @apply text-white;
      }

      .box-idea,
      .box-challenge,
      .box-ranking {
        border-color: #fcfc30;
      }
  }
}

.react-tiny-popover-container {
  z-index: 1000;
}

.pagination {
  // display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 14px;


  &.hidden {
    display: none;
  }

  li {
    margin: 0 5px;
    display: inline-block;
    color: #fff;

    a,
    .page-link {
      display: block;
      padding: 8px 12px;
      border: 1px solid #ccc;
      color: #fff;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;

      &.prev,
      &.next {
        display: flex;
      }

      &:hover {
        background-color: #f5f5f5;
        color: #555;
      }

      &.selected {
        background-color: var(--color-primary);
        color: #fff;
        border-color: var(--color-primary);
      }
    }
  }
}

button {
  &:disabled {
    opacity: 0.5;
  }
}

.global-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  button {
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #69696E;
    &.active {
      text-decoration-line: underline;
      color: #735cc6;
    }
  }
}

.tippy-box {
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}


.tippy-box[data-theme~='agente-ti'] {
  color: #465EFF;
}

.tippy-box[data-theme~='agente-comercial'],
.tippy-box[data-theme~='agente-negocial'] {
  color: #735CC6;
}

.tippy-box[data-theme~='agente-ti']>.tippy-svg-arrow,
.tippy-box[data-theme~='agente-comercial']>.tippy-svg-arrow,
.tippy-box[data-theme~='agente-negocial']>.tippy-svg-arrow {
  fill: white;
}

.tippy-box[data-theme~='agente-ti'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: white;
}

.tippy-box[data-theme~='agente-comercial'],
.tippy-box[data-theme~='agente-negocial'] {
  background-color: white;
  border-radius: 5px;
  color: #735CC6;
  padding: 5px;
}


.tippy-box[data-theme~='agente-comercial'][data-placement^='top']>.tippy-arrow::before,
.tippy-box[data-theme~='agente-negocial'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: white;
}
