
@import '../../assets/styles/mixin';

#auth {
  background-color: #fff;
  border-radius: 10px;

  form {
    width: 100%;
    margin: 0 auto;
    display: block;
    padding-top: 2.5rem;

    ul {
      &,
      li {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        margin-bottom: 0.938rem;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          width: 100%;
          @apply px-4 py-2 text-sm leading-none border block border-gray-300 rounded;
          height: 62px;
          background: #fff;
          border-radius: 70px;

          &[aria-invalid='true'] {
            border-color: #ff7171;
          }

          @include trans;
          opacity: 1;
          font-size: 1.3em;

          &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        span[role='alert'] {
          font-style: italic;
          font-size: 0.75rem;
          color: #ff7171;
          display: block;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.25rem;
      height: 2.5rem;
      background: transparent;
      border-radius: 100rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      border: 0.063rem solid #2475ff;
      margin: 0.875rem auto 0;
      @include trans;
      opacity: 1;
      width: 100%;
      height: 53px;
      background-color: #2475ff;

      &:hover {
        opacity: 0.75;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .cadastre {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.25rem;
      height: 2.5rem;
      background: transparent;
      border-radius: 100rem;
      color: #2475ff;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      border: 0.063rem solid #2475ff;
      margin: 0.875rem auto 0;
      @include trans;
      opacity: 1;
      width: 100%;
      height: 53px;
    }
  }

  .header {
    width: 100%;
    height: 140px;
    box-shadow: 0px 1px 3px 0px #0000001a;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #ddd;
  }

  .content {
    width: 100%;
    background: #f4f8ff;
    border-radius: 0 0 10px 10px;
    padding: 30px;
  }
}

.container {
  &.auth {
    &.no-aside {
      &.aside-min {
        height: 100vh;
        // background-image: url("/assets/images/banner-login.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
      }
    }
  }

  &.no-aside {
    &.aside-min {
      &.recovery {
        background-image: none !important;
      }
      &.register {
        background-image: none !important;
      }
    }
  }

  &.aside-full {
    background-image: none !important;
  }

  .auth-left {
    position: relative;

    div {
      > img {
        position: absolute;
        left: 22%;
        top: 43%;
        max-width: 85%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    .auth-left {
      width: 100%;
      height: 200px;
      display: block;

      div {
        > img {
          top: 30%;
          max-width: 60%;
          left: 0;
          margin: 0 auto;
          display: block;
          position: relative;
        }
      }
    }

    .auth-right {
      width: 100%;
    }

    #auth {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .auth-left {
      height: 130px;

      div {
        > img {
          max-width: 50%;
        }
      }
    }
  }
}

.cta{
    display: flex;
    align-items: center;
}
