.main-regulamento {
  .actions {
    > .btn-open-add {
      padding: 0.7rem 4rem;
      border-radius: 7px;
    }
  }

  .actionsbottom {
    margin-top: 4rem;
    display: flex;
    gap: 2rem;
    button {
      border-radius: 10px;
      background: #fcfc30;
      padding: 0.8rem 5rem;
      color: #69696e;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:last-child {
        background: transparent;
        border: solid 1px #fff;
        color: #fff;
      }
    }
  }

  .content {
    font-weight: 100;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
      font-weight: 900;
    }

    &.has-contrast {
      color: #fcfc30;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #fcfc30;
        font-weight: 900;
      }
    }

    ul {
      padding-left: 20px;
      li {
        list-style-type: disc;
      }
    }
  }
}
