.custom-notification-modal {
  .tabs {
    margin-top: 30px;

    > button {
      border: 2px solid rgb(115, 92, 198);;
      padding: 10px;
      width: 50%;

      &.active {
        border-bottom: 2px solid #fff;
      }
    }
  }

  .contentTabs {
    border: 2px solid rgb(115, 92, 198);;
    height: 100%;
    padding: 15px;
    margin-top: -2px;
    overflow-y: auto;
  }

  .accordion__button  {
    background: transparent !important;
  }

  .accordion {
    border: none !important;

  }
  
  img {
    margin: 0 auto;
    margin-top: 10px;
  }

  position: relative;
  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #838388;
    text-align: center;
    min-height: 300px;
    padding: 10px 0;
    img {
      margin: 1.25rem auto;
      max-width: 100%;
      text-align: center;
    }
    h3,h2 {
      --tw-text-opacity: 1;
      color: rgb(105 105 110 / var(--tw-text-opacity));
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.75rem;
    }
    p {
      --tw-text-opacity: 1;
      color: rgb(105 105 110 / var(--tw-text-opacity));
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  .wrapper {
    position: relative;
  }

  .btns {
    position: absolute;
    top: 18%;
    width: 100%;
    z-index: 99999;
    > .btn {
      cursor: pointer;
      left: 0;
      position: absolute;

      &:last-child {
        left: inherit;
        right: 0;
        z-index: 1000;
      }
    }
  }
    .swiper-pagination{
      bottom: 0;
    }

  .swiper-pagination-bullet {
    transition: 0.3s all;

    &.swiper-pagination-bullet-active {
      background: #735cc6;
      border-radius: 20px;
      width: 20px;
    }
  }
}

button.readNotification {
  position: fixed;
  right: 65px;
  bottom: 240px;
  font-size: 60px;

  &.vibrate-1 {
    color: #f70d0d;
  }
}

.vibrate-1 {
  -webkit-animation: vibrate-1 0.3s linear infinite both;
  animation: vibrate-1 0.3s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-10 17:46:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
